<template>
  <section class="hero ">
    <div class="page-section ">
        <h1 class="d-block d-sm-none pt-4">Az adataiddal könnyen visszaélhetnek</h1>
        <h2 class="d-block d-sm-none">a bántalmazók és zsarolók!</h2>
        <div class="home page-section-hero container-fluid d-flex justify-content-center position-relative">
            <h1 class="d-none d-sm-flex">Az adataiddal könnyen visszaélhetnek</h1>
            <h2 class="d-none d-sm-flex">a bántalmazók és zsarolók!</h2>
        </div>
    </div>
    <div class="page-section" style="background: #F4F4F4;">
        <div class="container-xl hero__container" id="section1">
            <div class="row">
                <div class="col-12">  
                    <p class="text py-4 m-0">
                        Az internet térhódításának nem csak pozitív következményei vannak. A közösségi felületeken napról-napra egyre több és több ember kap sértő, bántalmazó kommentet. A Samsung célja, hogy az emberek az okostelefonokat okosan is használják. Ezen a weboldalon folyamatosan frissülő anyagokat találsz a felelős telefonhasználatról és arról, hogyan állhatsz ki te is az internetes zaklatás ellen, hiszen nem buli a cyberbullying! 
                    </p>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>

export default {
}
</script>