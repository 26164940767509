<template>
  <div class="home ">
      <Hero />
      <MainText />
      <!-- <Volume /> -->
  </div>
</template>

<script>
import Hero from '@/components/Hero.vue'
import MainText from '@/components/MainText.vue'
// import Volume from '@/components/Volume.vue'

export default {
  name: 'Home',
  components:{
    Hero,
    MainText,
  }
  
}
</script>
