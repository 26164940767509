<template>
  <section class="mainText">
    <b-container fluid="xl">
        <b-row id="more" class="maintText__row"  v-for="(element, i) in sliced" :key="i">
            <b-col cols="12" lg="6" class="pb-5 d-flex flex-column"  v-for="(oneElement, k) in element" :key="k">
              <div>
                <img :src="'images/'+oneElement[1].img" class="w-100 margin-top-sm-44 cover-img"/>
                <h2 class="text-left pb-3">
                    {{ oneElement[1].title }}
                </h2>
                <p class="hashText text-left">{{ oneElement[1].subtitle }}</p>
                <div class="maintText__txt-content">
                    <p class="text m-0">
                        {{ oneElement[1].desc }}
                    </p>
                </div>
                <a :href="oneElement[1].link" class="more-btn mt-3 d-block">Elolvasom <img src="@/assets/images/icons/black-arrow-right.svg" class="ml-1"></a>
              </div>
            </b-col>
            <b-col cols="12" >
                <div class="overflow-hidden divider-container d-none d-lg-block" >
                    <img class=" section-divider m-auto" src="@/assets/images/main/divider-white.svg"/>
                </div>
            </b-col>
        </b-row>  
      
    </b-container>
  </section>
</template>
<script>
import articles from '../js/articles-data.json'

export default {
  data() {
    return {
      items: [],
      sliced: []
    }
  },
  mounted(){
    this.items = articles.articles;
    this.items = Object.entries(this.items).reverse();
    
   for(var i=0; i<this.items.length; i+=2){
      this.sliced.push(this.items.slice(i, i+2));
    }
  }
}
</script>
